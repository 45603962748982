<template>
  <v-dialog
    width="700"
    persistent
    dark
    transition="slide-x-transition"
    v-model="get_modal_view_permissao"
  >
    <v-card color="#333;" class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_permissao" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Perfil de Permissão
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_permissao">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome do perfil
              </span>
              <v-text-field
                v-model="get_permissao.nome"
                dense
                solo
                flat
                outlined
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="ex: ADMINISTRATOR GERAL"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <h2 class="fonte px-6">
                Seleciona as permissões por menu.
              </h2>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12>
              <v-timeline align-top dense>
                <v-timeline-item
                  fill-dot
                  color="green"
                  class="pr-3"
                  icon="mdi-lock"
                >
                  <v-card light class="expande-horizontal wrap">
                    <v-card-title>
                      <h3 class="fonte">Geral</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-flex class="px-3" xs12>
                        <v-select
                          v-model="get_permissao.permissoes"
                          multiple
                          label="Selecione as permissões gerais"
                          outlined
                          dense
                          :items="[
                            'dashboard',
                            'pdv',
                            'gestor-de-pedido',
                            'relatorios',
                            'controle-de-caixa',
                            'perfis',
                            'minhas-lojas',
                            'configurações'
                          ]"
                        ></v-select>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-flex class="py-3 pb-6" xs12>
                  <v-divider></v-divider>
                </v-flex>

                <v-timeline-item
                  fill-dot
                  color="green"
                  class="pr-3"
                  icon="mdi-storefront"
                >
                  <v-card light class="expande-horizontal wrap">
                    <v-card-title>
                      <h3 class="fonte">Meu Negócio</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-flex class="px-3" xs12>
                        <v-select
                          v-model="get_permissao.permissoes"
                          multiple
                          label="Selecione as permissões para cadastros"
                          outlined
                          dense
                          :items="[
                            'meu-negocio',
                            'meu-negocio.meu-cardapio',
                            'meu-negocio.meus-pedidos',
                            'meu-negocio.configurar-empresa',
                            'meu-negocio.meus-links-e-qrcode',
                            'meu-negocio.meus-clientes',
                            'meu-negocio.meus-fornecedores',
                            'meu-negocio.meus-colaboradores'
                          ]"
                        ></v-select>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  color="green"
                  class="pr-3"
                  icon="mdi-google-maps"
                >
                  <v-card light class="expande-horizontal wrap">
                    <v-card-title>
                      <h3 class="fonte">Rota Inicial</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-flex class="px-3" xs12>
                        <v-select
                          v-model="get_permissao.rota_inicial"
                          label="Escolha qual a primeira tela a ver!"
                          outlined
                          dense
                          item-text="nome"
                          return-object
                          :items="[
                            {
                              nome: 'Dashboard',
                              rota: '/dashboard'
                            },
                            {
                              nome: 'PDV',
                              rota: '/atendimento'
                            },
                            {
                              nome: 'Meu Negócio > Meu Cardápio',
                              rota: '/menuonline'
                            },
                            {
                              nome: 'Meu Negócio > Meus Pedidos',
                              rota: '/configurarpedidos'
                            },
                            {
                              nome: 'Meu Negócio > Minha Empresa',
                              rota: '/configurarempresa'
                            },
                            {
                              nome: 'Meu Negócio > Meus Links e QR Code',
                              rota: '/linkeqrcodes'
                            },
                            {
                              nome: 'Meu Negócio > Meus Clientes',
                              rota: '/clientes'
                            },
                            {
                              nome: 'Meu Negócio > Meus Fornecedores',
                              rota: '/fornecedors'
                            },
                            {
                              nome: 'Meu Negócio > Meus Colaboradores',
                              rota: '/funcionarios'
                            },
                            {
                              nome: 'Gestor de Pedidos',
                              rota: '/gestordepedidos'
                            },
                            {
                              nome: 'Relatórios',
                              rota: '/relatorios'
                            },
                            {
                              nome: 'Controle de Caixa',
                              rota: '/caixas'
                            },
                            {
                              nome: 'Perfis',
                              rota: '/perfis'
                            },
                            {
                              nome: 'Minhas Lojas',
                              rota: '/minhas-lojas'
                            },
                            {
                              nome: 'Configurações',
                              rota: '/configuracoes'
                            }
                          ]"
                        ></v-select>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-flex class="py-3 pb-6" xs12>
                  <v-divider></v-divider>
                </v-flex>
              </v-timeline>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_permissao",
      "getProfessores",
      "get_modal_view_permissao",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_permissao",
      "atualizar_permissao",
      "listarProfessores",
      "fecha_modal_view_permissao"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_permissao._id
          ? this.atualizar_permissao()
          : this.criar_permissao();
      }
    }
  },
  created() {
    this.listarProfessores();
  }
};
</script>
